// Loader.js
import React from "react";
import { CircularProgress, Typography, Box } from "@mui/material";

const Loader = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100%"
      textAlign="center"
      padding="4rem 1rem"
    >
      <CircularProgress size={60} thickness={4} color="primary" />
      <Typography variant="h6" my={2}>
        Please wait
      </Typography>
      <Typography variant="body2" color="textSecondary">
        Please be patient with us. Processing will take one to two minutes.
      </Typography>
    </Box>
  );
};

export default Loader;
