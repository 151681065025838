import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import "./App.css";
import App from "./App";
// import SaasApp from "./SaasApp.";
import "./i18next";

//importing context provider here
import { UserProvider } from "./contexts/User";
import { SettingsProvider } from "./contexts/Settings";
import { RestaurantProvider } from "./contexts/Restaurant";
import { FoodProvider } from "./contexts/Food";
import { ReservationProvider } from "./contexts/Reservation";
import SaasApp from "./SaasApp";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_KEY ||
    "pk_live_51PmjGVHz9mAChufFKmPBEj9kj9W8Shu6J7s7JlKR5LuZstP7mK2xmx1DeFbh09b7PP5aRWhQCZRA7pDcaHZm65Td00D5kM7r5w"
);
ReactDOM.render(
  <React.StrictMode>
    {/* options={options} */}
    <UserProvider>
      <SettingsProvider>
        <RestaurantProvider>
          <FoodProvider>
            <ReservationProvider>
              <Elements stripe={stripePromise}>
                <SaasApp />
              </Elements>
            </ReservationProvider>
          </FoodProvider>
        </RestaurantProvider>
      </SettingsProvider>
    </UserProvider>
  </React.StrictMode>,
  document.getElementById("khadyo")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
