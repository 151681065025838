import React, { useState, useEffect } from "react";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import Loader from "./Loader";

function PaymentComponent({ getToken, paymentModal }) {
  const stripe = useStripe();
  const elements = useElements();
  const [stripeDetails, setstripeDetails] = useState({
    stripe_card_name: null,
    // stripe_zip_code: null,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!paymentModal) {
      setLoading(false);
    }
  }, [paymentModal]);

  const onchangehandleStripePayment = (e) => {
    setstripeDetails({ ...stripeDetails, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet.
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { token, error } = await stripe.createToken(cardElement);

    if (error) {
      console.error("Error creating token:", error);
      // Optionally, display error to your customers
    } else {
      // Send the token to your server or handle it as needed
      if (getToken && token) {
        getToken(token, stripeDetails, setLoading);
        setLoading(true);
      }
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <form id="payment-form" onSubmit={handleSubmit}>
      <div className="form-row row">
        <div className="col-xs-12 form-group required">
          <label className="control-label">Name on Card</label>
          <input
            onChange={onchangehandleStripePayment}
            className="form-control"
            required
            name="stripe_card_name"
            size="4"
            placeholder="Name on Card"
            type="text"
          />
        </div>
      </div>
      {/* <div className="form-row row">
        <div className="col-xs-12 form-group required">
          <label className="control-label">Address zip code</label>
          <input
            onChange={onchangehandleStripePayment}
            className="form-control"
            required
            placeholder="Address zip code"
            name="stripe_zip_code"
            size="4"
            type="text"
          />
        </div>
      </div> */}
      <div className="col-xs-12 form-group required">
        <label className="control-label">Card Number</label>
        <div id="card-element">
          <CardElement />
        </div>
      </div>
      <button
        id="submit"
        className="payment-submit-button"
        type="submit"
        disabled={!stripe || loading}
      >
        {loading ? "Processing ..." : "Pay"}
      </button>
    </form>
  );
}

export default PaymentComponent;
