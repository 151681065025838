import React, { useState, useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import cafelogo from "../../../../assets/images/cafelogo.png";
import mapIcon from "../../../../assets/images/mapIcon.png";
import phoneIcon from "../../../../assets/images/icon _phone.png";
import cart from "../../../../assets/images/cart.png";
import samplePDFNew from "../../../../assets/files/Allergen.pdf";
import samplePDF from "../../../../assets/files/MENU.pdf";
import { BASE_URL } from "../../../../BaseUrl";
import { _t } from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

function NewHeader({ totalOrders, cookies, authUserInfo, deleteCookie }) {
  const { t, i18n } = useTranslation();

  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  useEffect(() => {
    if (!isNavCollapsed) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isNavCollapsed]);

  const handleLogout = () => {
    localStorage.removeItem("selectedAddress");
    localStorage.removeItem("n_b");
    localStorage.removeItem("user_p_c");
    localStorage.removeItem("selected_Address");
    localStorage.removeItem("delivery_type");
    deleteCookie();
  };
  const handleDownload = () => {
    // const link = document.createElement("a");
    // link.href = samplePDFNew;
    // link.download = "Alergan Info.pdf";
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
    window.open(samplePDFNew, "_blank");
  };
  const handleOurMenuDownload = () => {
    // const link = document.createElement("a");
    // link.href = samplePDFNew;
    // link.download = "Alergan Info.pdf";
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
    window.open(samplePDF, "_blank");
  };

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
  return (
    <header id="home">
      <div className="header-container">
        <div className="container-fluid position-relative">
          <div className="row d-flex align-items-center">
            <div className="col-lg-4 col-md-4 col-sm-12 px-md-0">
              <div className="d-flex flex-column flex-md-row align-items-center justify-content-evenly">
                <div className="navbar-logo">
                  <NavLink to="/" exact key="logokey">
                    <img
                      src={cafelogo}
                      alt="logo1"
                      style={{ width: "110px", height: "auto" }}
                    />
                  </NavLink>
                </div>
                <div className="deliver-container">
                  <img src={mapIcon} alt="map Icon" className="deliver-image" />
                  <p className="deliver-text">
                    Deliver to <br /> Bradford, United Kingdom
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 ">
              <p className="savor-text">Savor Every Bite</p>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 ">
              <div className="d-flex align-items-center pt-sm-2 pt-xl-0 justify-content-between flex-md-column flex-lg-column flex-xl-row number-container">
                <div className="phone-container">
                  <img
                    src={phoneIcon}
                    style={{ width: "30px", height: "30px" }}
                    alt="logo1"
                  />
                  <p
                    style={{
                      fontFamily: "Kite One",
                      fontSize: "20px",
                      letterSpacing: "2px",
                      fontWeight: "normal",
                      color: "#fff",
                      paddingLeft: "1rem",
                      marginBottom: "0px",
                    }}
                  >
                    01274723400
                  </p>
                </div>
                <div className="d-flex align-items-center">
                  {/* <div className="cart">
                    <img
                      src={cart}
                      style={{ width: "72px", height: "72px" }}
                      alt="logo1"
                    />
                    <div className="cart-counter">
                      <span>{totalOrders} </span>
                    </div>
                  </div> */}

                  {cookies === undefined ? (
                    <li style={{ listStyle: "none" }}>
                      <button className="login-btn">
                        <NavLink to="/login" exact key="logokey">
                          Login/Register
                        </NavLink>
                      </button>
                    </li>
                  ) : (
                    <div className="d-flex align-items-center ">
                      <div className="circle circle--sm rounded-circle avatar">
                        <img
                          src={
                            authUserInfo.details !== null &&
                            authUserInfo.details.image !== null
                              ? [BASE_URL + authUserInfo.details.image]
                              : "/assets/img/user.jpg"
                          }
                          alt=""
                          className="img-fluid avatar__img"
                        />
                      </div>
                      <div className="dropdown">
                        <a
                          className="font-weight-bold text-capitalize lg-text nav-link text-white dropdown-toggle pl-2"
                          href="#"
                          data-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {authUserInfo.details !== null &&
                            authUserInfo.details.name}
                        </a>
                        <ul className="dropdown-menu dropdown-menu-right">
                          {window.location.pathname === "/" ? (
                            <li>
                              <Link
                                to="/dashboard"
                                className="dropdown-item sm-text text-capitalize"
                              >
                                {_t(t("Dashboard"))}
                              </Link>
                            </li>
                          ) : (
                            <li key="homepage4">
                              <Link
                                to="/dashboard"
                                className="dropdown-item sm-text text-capitalize"
                              >
                                {_t(t("Dashboard"))}
                              </Link>
                            </li>
                          )}
                          <li key="homepage3">
                            <Link
                              to="/update-user-profile"
                              className="dropdown-item sm-text"
                            >
                              {_t(t("Change password"))}
                            </Link>
                          </li>
                          <li key="homepage5">
                            <Link
                              to="/profile"
                              className="dropdown-item sm-text"
                            >
                              {_t(t("Profile"))}
                            </Link>
                          </li>
                          <hr className="my-1" />

                          <li>
                            <button
                              className="dropdown-item sm-text text-capitalize"
                              onClick={handleLogout}
                              rel="noopener noreferrer"
                            >
                              {_t(t("Logout"))}
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="navbar-toggler-container">
        <div>
          <NavLink to="/" exact key="logokey">
            <img
              src={cafelogo}
              alt="logo1"
              style={{ width: "7rem", height: "4rem" }}
            />
          </NavLink>
        </div>

        <div className="d-flex align-items-center">
          {/* <div className="cart">
            <img
              src={cart}
              style={{ width: "72px", height: "72px" }}
              alt="logo1"
            />
             <div className="cart-counter">
              <span>0</span>
            </div> 
          </div> */}
          <button
            className="navbar-toggler ml-2"
            type="button"
            // data-toggle="collapse"
            // data-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={handleNavCollapse}
          >
            <span class="navbar-toggler-icon"></span>
          </button>
        </div>
      </div>

      <div className="navbar-container">
        <nav className="navbar navbar-expand-lg navbar-light">
          <div
            // className="collapse navbar-collapse justify-content-center"
            className={`collapse navbar-collapse justify-content-center ${
              !isNavCollapsed && "show"
            }`}
            id="navbarNavDropdown"
          >
            <ul class="navbar-nav">
              {/* <li class="nav-item active">
              <div className=" nav-link deliver-container">
                <img
                  src={mapIcon}
                  alt="map Icon"
                  className="deliver-image"
                />
                <p className="deliver-text">
                  Deliver to <br /> Bradford, United Kingdom
                </p>
              </div>
              </li> */}
              {/* <li class="nav-item active">
              <p className="nav-link savor-text text-left">
                Savor Every Bite
              </p>
              </li> */}
              <li class="nav-item  menu-for-mobile">
                <div className="nav-link phone-container">
                  <img
                    src={phoneIcon}
                    style={{ width: "25px", height: "25px" }}
                    alt="logo1"
                  />
                  <p
                    style={{
                      fontFamily: "Kite One",
                      fontSize: "1.4rem",
                      letterSpacing: "1.5px",
                      fontWeight: 900,
                      color: "#fff",
                      paddingLeft: "1rem",
                      marginBottom: "0px",
                    }}
                  >
                    01274723400
                  </p>
                </div>
              </li>
              <li className="nav-item menu-for-mobile">
                <div className="d-flex justify-content-start align-items-center">
                  {cookies === undefined ? (
                    <a class="nav-link" href="/login">
                      Login/Register
                    </a>
                  ) : (
                    <a
                      className="font-weight-bold text-capitalize lg-text nav-link text-white pl-2"
                      href="#"
                      data-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Signed in as {""}
                      {authUserInfo?.details !== null
                        ? authUserInfo?.details?.name
                        : ""}
                      {""}
                    </a>
                  )}
                  {/* <button className="login-btn ml-0">Login/Register</button> */}
                </div>
              </li>
              <li class="nav-item active">
                <div
                  class="nav-link"
                  style={{ cursor: "pointer" }}
                  onClick={handleDownload}
                >
                  Alergan Info
                </div>
              </li>
              <li class="nav-item active menu-for-mobile">
                <NavLink class="nav-link" to="/onlineorder" exact key="logokey">
                  Order Now
                </NavLink>
              </li>
              <li class="nav-item active menu-for-mobile">
                <NavLink class="nav-link" to="/uncoocked" exact key="logokey">
                  Uncooked Range
                </NavLink>
              </li>
              <li class="nav-item active menu-for-mobile">
                <NavLink class="nav-link" to="/desserts" exact key="logokey">
                  Cakes & dessert
                </NavLink>
              </li>
              <li class="nav-item active menu-for-mobile">
                <div
                  class="nav-link"
                  style={{ cursor: "pointer" }}
                  onClick={handleOurMenuDownload}
                >
                  Our menu
                </div>
              </li>
              <li class="nav-item active menu-for-mobile">
                <NavLink class="nav-link" to="/gifts" exact key="logokey">
                  Gift cards
                </NavLink>
              </li>
              <li class="nav-item active menu-for-mobile">
                <NavLink
                  class="nav-link"
                  to="/reserve-online"
                  exact
                  key="logokey"
                >
                  Catering & events
                </NavLink>
              </li>
              {/* <li class="nav-item active menu-for-mobile">
                <div
                  class="nav-link"
                  style={{ cursor: "pointer" }}
                  onClick={handleDownload}
                >
                  Back button
                </div>
              </li> */}
              <li class="nav-item">
                <NavLink class="nav-link" to="/gallery2" exact key="logokey">
                  Gallery
                </NavLink>
              </li>
              <li class="nav-item">
                <NavLink class="nav-link" to="/feedback" exact key="logokey">
                  Feedback
                </NavLink>
              </li>
              <li class="nav-item">
                <NavLink class="nav-link" to="/faqs" exact key="logokey">
                  FAQs
                </NavLink>
              </li>
              {cookies === undefined ? (
                ""
              ) : (
                <li class="nav-item menu-for-mobile">
                  <a
                    className="bg font-weight-bold text-capitalize lg-text nav-link text-white "
                    onClick={handleLogout}
                    exact
                    key="logokey"
                  >
                    Logout
                  </a>
                </li>
              )}
              <li class="nav-item">
                <div>&nbsp;</div>
              </li>
              <li class="nav-item">
                <div>&nbsp;</div>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
}

export default NewHeader;
